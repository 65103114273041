<template>
  <div class="container-stats">
    <div class="container-statistics">
      <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
        <li>
        <router-link to="/demo/digitalpoem/model">
          <span>Model</span>
        </router-link>
        </li>
        <li class="ProximaNovaBold">
          Sale Statistics
        </li>
      </ul>
      <div class="d-flex justify-content-between align-items-center">
        <div class="title">Sales-Statistics</div>
        <!--<div>
          <div class="" style="height: 40%">
            <div class="w-100">
              <w-white-dropdown
                :options="statsDropdown"
                :placeHolder="'Select'"
                :labelText="'Model'"
                :selectedOption="selectedstat"
                @input="selectOption($event)"
              ></w-white-dropdown>
            </div>
          </div>
        </div>-->
      </div>

      <w-chart-overview :chartSummary="salseStatics"></w-chart-overview>
      <!-- <div v-for="(statics, index) in salseStatics" :key="index" class="item">
          <div class="item-img" :class="statics.className">
            <i class="fas fa-chart-pie" style="font-size: 24px"></i>
          </div>

          <div class="item-text">
            <div class="item-text__title">{{ statics.name }}</div>
            <div class="item-text__subtitle">{{ statics.value }}</div>
          </div>
        </div> -->
    </div>

    <div class="container-line-chart">
      <div class="title">Actual vs Prediction</div>

      <line-chart
        yAxisTitle="Millions"
        :boxshadow="true"
        :chartData="lineChartData"
      />
    </div>

    <div class="text-right">
      <w-button
        :buttonText="'Progress'"
        class="my-5"
        :buttonLoader="'normal'"
        @buttonClicked="nextToTheInsights"
      >
      </w-button>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/Chart/LineChart.vue";
// import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import { DigitalPoemServices } from "@/services/DigitalPoemServices.js";
import Button from "@/components/Profile/Button.vue";
const digitalPoemService = new DigitalPoemServices();
export default {
  name: "Stats",
  components: {
    LineChart,
    "w-button": Button,
    // "w-white-dropdown": WhiteDropdown,
    "w-chart-overview": ChartOverview,
  },
  data() {
    return {
      selectedstat: "",
      statsDropdown: [
        { text: "Elite", id: "elite" },
        { text: "Pro", id: "pro" },
        { text: "Lite Plan", id: "Lite Plan" },
      ],
      lineChartData: {
        series: [
          {
            data: [1, 2, 3, 3, 2, 1, 4, 5, 3, 7, 9, 10],
            marker: {
              symbol: "circle",
              radius: 5,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Actual sales",
          },
          {
            data: [3, 2, 1, 2, 1, 4, 1, 2, 3, 3, 9, 10],
            marker: {
              symbol: "circle",
              radius: 5,
            },
            type: "spline",
            name: "Predicted sales",
          },
        ],
        title: {
          text: "Test Dataset",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        legend: {
          align: "right",
          verticalAlign: "top",
          y: -20,
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemMarginBottom: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        xAxis: {
          title: {
            text: "Weeks",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        colors: ["#050505", "#18ffff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function() {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      salseStatics: [
        {
          name: "R2_Score",
          value: "0.86",
          className: "blue-pastel",
          image: "R2_Score",
        },
        {
          name: "Actual_Mean",
          value: "21.05M",
          className: "green-pastel",
          image: "R2_Score",
        },
        {
          name: "Predicted_Mean",
          value: "21.91M",
          className: "pink-pastel",
          image: "R2_Score",
        },
        {
          name: "Actual_Std",
          value: "17.62M",
          className: "red-pastel",
          image: "R2_Score",
        },
        {
          name: "Predicted_Std",
          value: "21.84M",
          className: "yellow-pastel",
          image: "R2_Score",
        },
      ],
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
    };
  },
  methods: {
    selectOption(e) {
      this.selectedstat = e;
    },
    nextToTheInsights() {
      this.$router.push("/demo/digitalpoem/insights");
    },
    getStaticsData() {
      digitalPoemService
        .getChartData(
          "actual vs prediction-digitalpoem",
          "/b2c/in/",
          "salesstatistics",
          "no"
        )
        .then((res) => {
          this.salseStatics = [];
          for (var i = 0; i < res.length; i++) {
            this.salseStatics.push({
              name: res[i].name,
              value: res[i].value,
              className: this.classess[i],
              image: res[i].name + ".svg",
            });
          }
        });
    },
    getActualChartData() {
      digitalPoemService
        .getChartData(
          "actual vs prediction-digitalpoem",
          "/b2c/in/",
          "actualvsprediction",
          "no"
        )
        .then((res) => {
          this.lineChartData.series[0].data = [];
          this.lineChartData.series[1].data = [];
          for (var i = 0; i < res.length; i++) {
            this.lineChartData.series[0].data.push(res[i].actual);
            this.lineChartData.series[1].data.push(res[i].prediction);
          }
        });
    },
  },
  created() {
    this.getActualChartData();
    this.getStaticsData();
  },
};
</script>

<style lang="css" scoped>
.container-stats {
  width: 93%;
  margin: 71px auto 0 auto;
}

.container-statistics {
  padding-top: 1px;
}

.title {
  font-family: ProximaNovaBold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  margin-top: 48px;
  margin-bottom: 32px;
}

.wrapper-statistics {
  box-shadow: 0px 0px 16px -13px rgb(0 0 0);
  border-radius: 4px;
  min-height: 126px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.item {
  display: flex;
  width: 20%;
  position: relative;
}

.item:not(:last-child):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>
